import React, { useState } from "react";
import { BrowserRouter as Router, 
  Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard";

import config from "./config";

import './App.css';

function Login({ onLogin })
{
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);

  const handleLogin = async (e) =>
  {
    e.preventDefault();

    try
    {
      const response = await fetch(
        `${config.FULL_API_URL}/api/v1/virus-game/auth/sign-in`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      console.log(`Claimed auth data: ${data}`);

      if (data.isSuccess)
      {
        console.log(`Successfully authorized with session: ${JSON.stringify(data)}`)

        setIsSuccess(true);

        onLogin(data.token);

        return;
      }

      setMessage('Вход отклонён, данные не совпадают...');

      console.log(`Failed to authorize to backend, wroong credentials`);

      setIsSuccess(false);
    }
    catch (error)
    {
      setMessage('Ошибка соединения с сервером');

      setIsSuccess(false);

      console.log(`Failed to authorize to backend`, error);
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleLogin}>
        <h2>Админ панель VRS</h2>
        <input
          id="username"
          type="text"
          placeholder="Введите логин"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          id="password"
          type="password"
          placeholder="Введите пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Войти в систему</button>
        <br/>
        {message && 
        (
          <p className={`message ${isSuccess ?
             'success' : 'error'}`}>
              {message}
          </p>
        )}
      </form>
    </div>
  );
}

function App()
{
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessionToken, setToken] = useState('');

  const handleLogin = (token) =>
  {
    setIsLoggedIn(true);
    setToken(token);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/dashboard"/> : <Login onLogin={handleLogin}/>}
        />
        <Route
          path="/dashboard"
          element={isLoggedIn ? <Dashboard sessionToken={sessionToken}/> : <Navigate to="/"/>}
        />
      </Routes>
    </Router>
  );
}

export default App;