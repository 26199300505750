import React, { useCallback, useEffect, useState } from 'react';
import FlipClock from './FlipClockTimer';

import config from "./config";

import './DailyCombo.css';

const tileSprites =
[
    { id: 1, src: '/assets/ComboIcon/VirusCell_1.png' },
    { id: 2, src: '/assets/ComboIcon/VirusCell_2.png' },
    { id: 3, src: '/assets/ComboIcon/VirusCell_3.png' },
    { id: 4, src: '/assets/ComboIcon/VirusCell_4.png' },
    { id: 5, src: '/assets/ComboIcon/VirusCell_5.png' },
    { id: 6, src: '/assets/ComboIcon/VirusCell_6.png' },
    { id: 7, src: '/assets/ComboIcon/VirusCell_7.png' },
    { id: 8, src: '/assets/ComboIcon/VirusCell_8.png' }
]

const DailyCombo = ({ sessionToken }) =>
{
    const [comboTiles, setComboTiles] = useState([]);
    const [timeLeft, setTimeLeft] = useState(null);
    const [comboStatus, setComboStatus] = useState('current');

    const fetchPreviewCombo = useCallback(async () =>
    {
        try
        {
            const response = await fetch(
                `${config.FULL_API_URL}/api/v1/virus-game/game-stat/combo`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sessionToken }),
            });
            
            const data = await response.json();

            console.log(`Loaded daily combo data: ${JSON.stringify(data)}`);

            setComboTiles(data.dailyCombo.map(id => tileSprites[id - 1]));
            setTimeLeft(data.timeLeft);
            setComboStatus(data.type);
        }
        catch (error)
        {
            console.error(`Failed to load daily combo data`, error);
        }
    }, [sessionToken]);

    useEffect(() =>
    {
        fetchPreviewCombo();
    }, [fetchPreviewCombo]);

    useEffect(() =>
    {
        if (timeLeft !== null)
        {
            const interval = setInterval(() =>
            {
                setTimeLeft(prevTime =>
                {
                    if (prevTime <= 1)
                    {
                        clearInterval(interval);

                        fetchPreviewCombo();

                        return null;
                    }

                    return prevTime - 1;
                });
            }, 1000);
        
            return () => clearInterval(interval);
        }
    }, [timeLeft, fetchPreviewCombo]);

    return (
       <div className="daily-combo-container">
            <div className='section-container'>
                <h2 className='viruses-header'>Следующее обновление:</h2>
                {
                    timeLeft !== null ? 
                    <FlipClock timeLeft={timeLeft}/>
                    : <h1>Загрузка...</h1>
                }
                <h2 className='viruses-header'>
                    {
                        comboStatus === "preview" ? 
                        "Будущая комбинация:" : "Текущая комбинация:"
                    }
                </h2>
                <div className='tile-grid'>
                    {comboTiles.map((tile, index) =>
                    (
                        <div key={index} className='tile-display'>
                            <img src={tile.src} 
                                alt={`Combo Tile ${index}`} 
                                className="combo-tile-image"/>
                        </div>
                    ))}
                </div>
            </div>
       </div>
    );
};

export default DailyCombo;